import {
  scrollSpyIdFromAncestors,
  scrollSpyId,
  bodyScrollEl,
  getOffsetTop
} from '~/utils/scrollSpy/utils'
import {
  DefaultOptions,
  ScrollSpyElement
} from '~/types/composables/useScrollSpy'
import { scrollWithAnimation } from '~/utils/scrollSpy/animate'
import { DirectiveBinding } from 'vue'

const scrollSpyContext = '@@scrollSpyContext'
const scrollSpyElements: { [key: string]: ScrollSpyElement } = {}
const scrollSpySections: { [key: string]: HTMLCollection | Element[] } = {}
const activeElement: { [key: string]: ScrollSpyElement } = {}
const allActiveElements: { [key: string]: ScrollSpyElement[] } = {}
const currentIndex: { [key: string]: number | null } = {}
const defaults: DefaultOptions = {
  /**
   * allow no active sections when scroll position is outside
   *   the scroll-spy container. Default behavior is to keep
   * active at least one section in any case.
   */
  allowNoActiveElement: false,
  /** The scrollable container */
  sectionSelector: null,
  /** Scroll offset from top */
  indexChanged: null,
  offset: 0,
  /** Animation timing */
  time: 500,
  /** Animation increment step  */
  steps: 30,
  /** Animation easing type */
  easing: null,
  /** Active class options */
  active: {
    selector: null,
    class: 'active'
  },
  adjustScrollTop: 0,
  /** Link options */
  link: {
    selector: 'a'
  }
}

const defaultOptions = Object.assign({}, defaults)

function findElements(
  container: HTMLElement,
  selector: string | null | undefined
) {
  if (!selector) {
    return [...container.children].map((el) => {
      return initScrollSpyElement(el as HTMLElement)
    })
  }

  const id = scrollSpyId(container)

  const elements = []

  for (const el of container.querySelectorAll(selector)) {
    // Filter out elements that are owned by another directive
    if (scrollSpyIdFromAncestors(el) === id) {
      elements.push(initScrollSpyElement(el as HTMLElement))
    }
  }

  return elements
}

function scrollLinkClickHandler(
  index: number,
  scrollSpyId: string,
  event: Event
) {
  event.preventDefault()
  scrollToLink(scrollSpyElements[scrollSpyId], index)
}

function scrollToLink(el: HTMLElement, index: number) {
  const id = scrollSpyId(el)
  const idScrollSections = scrollSpySections[id]

  const scrollSpyContextData = (el as ScrollSpyElement)[scrollSpyContext]
  const { scrollEl, options } = scrollSpyContextData
  const current = scrollEl.scrollTop

  // when initial scroll is 0, so we need to add the activeBorderHeight to the top,
  // otherwise the scroll will be short of the active section
  // adjust activeLinkBorderHeight will scroll to the correct position
  let initializeScrollTop = 0

  if (current === 0) {
    initializeScrollTop = options.adjustScrollTop || 0
  }

  if (idScrollSections[index]) {
    const target =
      Math.ceil(
        getOffsetTop(idScrollSections[index] as HTMLElement) - options.offset
      ) + 1
    if (options.easing) {
      scrollWithAnimation(
        scrollEl,
        current,
        target,
        options.time,
        options.easing
      )
      return
    }

    const ua = window.navigator.userAgent
    const msie = ua.indexOf('MSIE ')

    // If current browser is Internet Explorer.
    if (msie > 0) {
      const time = options.time
      const steps = options.steps
      const timeMs = time / steps
      const gap = target - current
      for (let i = 0; i <= steps; i++) {
        const pos = current + (gap / steps) * i
        setTimeout(() => {
          scrollEl.scrollTop = pos
        }, timeMs * i)
      }
      return
    }

    window.scrollTo({
      top: target + initializeScrollTop,
      behavior: 'smooth'
    })
  }
}

function initScrollSections(
  el: HTMLElement,
  sectionSelector: string | null | undefined
) {
  const id = scrollSpyId(el)
  const scrollSpyContextEl = (el as ScrollSpyElement)[scrollSpyContext]
  const idScrollSections = findElements(el, sectionSelector)
  const removeSections = scrollSpyContextEl.options.removeSections

  scrollSpySections[id] = idScrollSections

  if (idScrollSections[0]?.offsetParent !== el) {
    scrollSpyContextEl.eventEl = window as unknown as Window
    scrollSpyContextEl.scrollEl = bodyScrollEl as unknown as HTMLElement
  }
}

function initScrollLink(el: HTMLElement, selector: string) {
  const id = scrollSpyId(el)
  const linkElements = findElements(el, selector)

  for (let i = 0; i < linkElements.length; i++) {
    const linkElement = linkElements[i]

    const listener = scrollLinkClickHandler.bind(null, i, id)

    if (!linkElement[scrollSpyContext].click) {
      linkElement.addEventListener('click', listener)
      linkElement[scrollSpyContext].click = listener
    }
  }
}

function initScrollSpyElement(el: HTMLElement): ScrollSpyElement {
  const onScroll = () => {
    return
  }
  ;(el as ScrollSpyElement)[scrollSpyContext] = {
    onScroll,
    options: defaultOptions,
    id: '',
    eventEl: el,
    scrollEl: el
  }

  return el as ScrollSpyElement
}

function scrollSpyActive(
  el: ScrollSpyElement,
  binding: DirectiveBinding
): void {
  const id = scrollSpyId(el)
  const activeOptions = Object.assign({}, defaultOptions, {
    active: {
      selector:
        binding.value && binding.value.selector
          ? binding.value.selector
          : defaultOptions.active.selector,
      class:
        binding.value && binding.value.class
          ? binding.value.class
          : defaultOptions.active.class
    }
  })

  const arr = [...findElements(el, activeOptions.active.selector)]

  allActiveElements[id] = arr.map((el: ScrollSpyElement) => {
    el[scrollSpyContext].options = activeOptions
    return el
  })
}

function scrollSpyLink(el: HTMLElement, binding: DirectiveBinding) {
  const linkOptions = Object.assign({}, defaultOptions.link, binding.value)
  initScrollLink(el, linkOptions.selector)
}

function removeScrollSpyLink(el: HTMLElement) {
  const linkElements = findElements(el, null)

  for (let i = 0; i < linkElements.length; i++) {
    const linkElement = linkElements[i]
    const id = scrollSpyId(el)
    const listener = scrollLinkClickHandler.bind(null, i, id)

    if (linkElement[scrollSpyContext].click) {
      linkElement.removeEventListener('click', listener)
      delete linkElement[scrollSpyContext]['click']
    }
  }
}

export {
  scrollSpyContext,
  scrollSpySections,
  scrollSpyElements,
  scrollSpyActive,
  defaultOptions,
  allActiveElements,
  findElements,
  scrollSpyLink,
  initScrollSections,
  removeScrollSpyLink
}
