function getOffsetTop(
  // eslint-disable-next-line
  elem: any,
  // eslint-disable-next-line
  untilParent: any = null
): number {
  let offsetTop = 0
  if (elem === null) {
    return 0
  }
  do {
    if (!isNaN(elem.offsetTop)) {
      // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
      offsetTop += elem.offsetTop
    }
    const offsetParent = elem.offsetParent
    if (offsetParent === null) break
    elem = offsetParent
  } while (Boolean(elem) && elem !== untilParent)
  return Math.ceil(offsetTop)
}

function scrollSpyIdDefined(el: Element): boolean {
  return (
    !!el.getAttribute('data-scroll-spy-id') ||
    !!el.getAttribute('scroll-spy-id')
  )
}

const bodyScrollEl = {}

Object.defineProperty(bodyScrollEl, 'scrollTop', {
  get(): number {
    return document.body.scrollTop || document.documentElement.scrollTop
  },
  set(val): void {
    document.body.scrollTop = val
    document.documentElement.scrollTop = val
  }
})

Object.defineProperty(bodyScrollEl, 'scrollHeight', {
  get(): number {
    return document.body.scrollHeight || document.documentElement.scrollHeight
  }
})

Object.defineProperty(bodyScrollEl, 'offsetHeight', {
  get(): number {
    return window.innerHeight
  }
})

function scrollSpyId(el: Element): string {
  return (
    el.getAttribute('data-scroll-spy-id') ||
    el.getAttribute('scroll-spy-id') ||
    el.getAttribute('id') ||
    'default'
  )
}

function scrollSpyIdFromAncestors(el: Element): string {
  do {
    if (scrollSpyIdDefined(el)) {
      return scrollSpyId(el)
    }
    el = el.parentElement as HTMLElement
  } while (el)
  return 'default'
}

export { bodyScrollEl, scrollSpyIdFromAncestors, scrollSpyId, getOffsetTop }
