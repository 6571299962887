import { Directive, DirectiveBinding } from 'vue'
import { ScrollSpyElement } from '~/types/composables/useScrollSpy'
import { Easing } from '~/utils/scrollSpy/animate'
import { getOffsetTop, scrollSpyId } from '~/utils/scrollSpy/utils'
import { debounce } from 'lodash-es'

import {
  defaultOptions,
  allActiveElements,
  initScrollSections,
  scrollSpyLink,
  scrollSpySections,
  scrollSpyContext,
  scrollSpyElements,
  removeScrollSpyLink,
  scrollSpyActive
} from '~/utils/scrollSpy/factory'

const activeElement: { [key: string]: ScrollSpyElement } = {}
const currentIndex: { [key: string]: number | null } = {}

export function useScrollSpy() {
  const vScrollSpy: Directive = {
    created(el, binding: DirectiveBinding) {
      const onScroll = debounce(() => {
        const id = scrollSpyId(el)
        const idScrollSections = scrollSpySections[id]
        const { scrollEl, options } = el[scrollSpyContext]

        let index: number | null

        if (
          scrollEl.offsetHeight + scrollEl.scrollTop >=
          scrollEl.scrollHeight - 10
        ) {
          index = idScrollSections.length
        } else {
          for (index = 0; index < idScrollSections.length; index++) {
            if (
              getOffsetTop(idScrollSections[index], scrollEl) - options.offset >
              Math.ceil(scrollEl.scrollTop)
            ) {
              break
            }
          }
        }
        index--

        if (index < 0) {
          index = options.allowNoActiveElement ? null : 0
        } else if (
          options.allowNoActiveElement &&
          index === idScrollSections.length - 1
        ) {
          const idScrollSection = idScrollSections[index]
          if (
            idScrollSection instanceof HTMLElement &&
            getOffsetTop(idScrollSections[index]) +
              idScrollSection.offsetHeight -
              options.offset <
              Math.ceil(scrollEl.scrollTop)
          ) {
            index = null
          }
        }

        if (
          (!options.allowNoActiveElement && index === 0) ||
          index !== currentIndex[id]
        ) {
          let idActiveElement = activeElement[id]
          if (idActiveElement) {
            idActiveElement.classList.remove(
              idActiveElement[scrollSpyContext].options.active.class
            )
            delete activeElement[id]
          }

          currentIndex[id] = index

          if (
            index !== null &&
            (idActiveElement = allActiveElements[id]?.[index])
          ) {
            activeElement[id] = idActiveElement
            idActiveElement.classList.add(
              idActiveElement[scrollSpyContext].options.active.class
            )
          }

          if (
            options.section !== null &&
            typeof options.indexChanged === 'function' &&
            index !== null
          ) {
            options.indexChanged(index)
          }
        }
      }, 150)

      const id = scrollSpyId(el)

      el[scrollSpyContext] = {
        onScroll,
        options: Object.assign({}, defaultOptions, binding.value),
        id: scrollSpyId(el),
        eventEl: el,
        scrollEl: el
      }

      scrollSpyElements[id] = el
      delete currentIndex[id]
    },
    mounted(el) {
      const {
        options: { sectionSelector }
      } = el[scrollSpyContext]
      initScrollSections(el, sectionSelector)
      const { eventEl, onScroll } = el[scrollSpyContext]
      eventEl.addEventListener('scroll', onScroll)

      onScroll()
    },
    updated(el, binding) {
      el[scrollSpyContext].options = Object.assign(
        {},
        defaultOptions,
        binding.value
      )

      const {
        onScroll,
        options: { sectionSelector }
      } = el[scrollSpyContext]

      initScrollSections(el, sectionSelector)
      onScroll()
    }
  }

  const vScrollSpyActive: Directive = {
    created: scrollSpyActive,
    mounted: scrollSpyActive,
    updated: scrollSpyActive
  }

  const vScrollSpyLink: Directive = {
    mounted: scrollSpyLink,
    updated: scrollSpyLink,
    unmounted: removeScrollSpyLink
  }

  return { vScrollSpy, vScrollSpyActive, vScrollSpyLink, Easing }
}
